<template>
    <ClientsQuickSearch class="clients-quick-search" placeholder="Sök klient..." :options="clientsMaped" @on-selected="onClientChange" :selected="preselectedClient" style="width: 300px;" />
</template>
<script>
import queryString from "query-string";
import Api from "../avstamning.api";

export default {
    data() {
        return {
            clientsData: [],
            preselectedClient: {},
        };
    },

    created() {
        this.getClientsData();
        this.checkPreselectedClient();
    },

    components: {
        ClientsQuickSearch: () => import("../../../components/searchClient.vue"),
    },

    methods: {
        checkPreselectedClient() {
            const { clientId } = queryString.parse(window.location.search, { sort: false });
            this.preselectedClient = {
                value: clientId,
            };
        },

        async getClientsData() {
            this.clientsData = await Api.getClients();
        },

        async onClientChange(client) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.clientId = client.value;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("selected", client.value);
        },
    },
    computed: {
        clientsMaped() {
            return this.clientsData.map(item => ({
                name: item.searchName,
                value: item.value,
            }));
        },
    },
};
</script>
<style>
.clients-quick-search .input {
    height: 28px;
    font-size: 15px;
}
</style>
